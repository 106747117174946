/* eslint-disable class-methods-use-this */
// Using Vue.Observable for this.state management instead of Vuex for now
import Vue from 'vue';
import { jwtDecode } from 'jwt-decode';
import { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import { Response } from '@/services/common/Response';
import EventBus from '@/services/common/EventBus';
import { CTRole } from '@/types/ct-users-api-types';
import { config as runtimeConfig } from './common/RuntimeConfig';

// const ROOT = '/#/my-portal/';
const API_GATEWAY_ROOT = process.env.VUE_APP_API_SERVICE_IDENTITY ?? (process.env.VUE_APP_API_GATEWAY || runtimeConfig.VUE_APP_API_GATEWAY);

export enum Roles {
  ADMIN = 1,
  RETAIL_CLERK = 2,
  RETAIL_SUPERVISOR = 3,
  MARKETING = 34,
  MARKETING_MANAGER = 35,
  TECH = 36,
  TECH_SUPERVISOR = 37,
  DEVELOPER = 38,
  PROCESS_MANAGER = 39
}

export interface User {
  id: string;
  username: string;
  fullname: string;
  givenName: string;
  familyName: string;
  roles: Roles[];
  scope: string;
  legacyUserId: number;
}

interface DecodedIdentity {
  /* eslint-disable camelcase */
  sub: string;
  preferred_username: string;
  name: string;
  given_name: string;
  family_name: string;
}

interface RealmAccess {
  roles: [];
}

interface DecodedAuth {
  /* eslint-disable camelcase */
  realm_access: RealmAccess;
  legacy_user_id: number;
  scope: string;
}

// interface UserToLogin {
//   username: string;
//   password: string;
// }

interface AuthState {
  token: string;
  identity: string;
  authenticated: boolean;
  user: User | null;
}

export interface Session {
  id: string;
  start: string;
  lastAccess: string;
  clientIp?: string;
}

export interface ChangePasswordOptions {
  oldPassword: string;
  newPassword: string;
}

const DEFAULT_USER = {
  id: '',
  username: '',
  fullname: '',
  givenName: '',
  familyName: '',
  roles: [],
  scope: '',
  keepLoggedIn: false,
  identity: '',
  legacyUserId: -1
};

const createUserToStore = (
  authToken: string,
  identityToken: string,
): User => {
  const identityDecoded: DecodedIdentity = jwtDecode(identityToken);
  const accessDecoded: DecodedAuth = jwtDecode(authToken);

  const newUser: User = {
    id: identityDecoded.sub,
    username: identityDecoded.preferred_username,
    fullname: identityDecoded.name,
    givenName: identityDecoded.given_name,
    familyName: identityDecoded.family_name,
    roles: [],
    scope: accessDecoded.scope,
    legacyUserId: Number(accessDecoded.legacy_user_id) || -1
  };

  return newUser;
};

const sameIdentity = (
  identityToken1: string,
  identityToken2: string
): boolean => {
  const identityDecoded1: DecodedIdentity = jwtDecode(identityToken1);
  const identityDecoded2: DecodedIdentity = jwtDecode(identityToken2);

  return identityDecoded1.sub === identityDecoded2.sub;
};

class AuthService {
  public getAuthenticatedUser(): User {
    return this.state.user ? this.state.user : DEFAULT_USER;
  }

  public isAuthenticated(): boolean {
    return !!this.state.authenticated;
  }

  public async storeUser(
    authToken: string,
    identityToken: string,
    authenticated: boolean
  ) {
    this.state.token = authToken;
    this.state.identity = identityToken;
    this.state.authenticated = authenticated;

    this.state.user = createUserToStore(
      authToken,
      identityToken,
    );

    this.interceptor = Vue.axios.interceptors.request.use(
      async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
        if (this.state.token) {
          const headers: AxiosRequestHeaders = config.headers ?? {};

          headers.Authorization = `Bearer ${this.state.token}`;

          // eslint-disable-next-line no-param-reassign
          config.headers = headers;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    await this.loadRoles();

    EventBus.emit('auth:login', undefined);
  }

  public async logout() {
    // Clear local identity cache
    this.clearAuthentication();

    const kc = Vue.prototype.$keycloak.keycloak;

    kc.logout({ redirectUri: window.location.origin });

    EventBus.emit('auth:logout', undefined);
  }

  public async refresh(newAuthToken: string, newIdentityToken: string, authenticated: boolean) {
    let originalIdentity = '';

    if (this.state.user) {
      originalIdentity = this.state.identity;
    } else {
      this.logout();
    }

    if (originalIdentity !== '') {
      // Clear the interceptor as its no longer valid
      // we will add it back if refresh is successful
      this.disableAuthHeader();

      try {
        if (sameIdentity(originalIdentity, newIdentityToken)) {
          await this.storeUser(newAuthToken, newIdentityToken, authenticated);
        } else {
          throw new Error('Refresh this.state is no longer valid');
        }
      } catch (error) {
        this.logout();

        throw error;
      }
    } else {
      // If we are requesting refresh but don't have a
      // a user to refresh we can't refresh or logout
      // so clear any remaining local cache of identity
      // and resolve
      this.clearAuthentication();
    }
  }

  public async loadRoles() {
    const res = await Vue.axios.get<CTRole[]>(
      `${API_GATEWAY_ROOT}/users/user-roles`
    );

    if (this.state.authenticated && this.state.user) {
      this.state.user.roles = res.data.map((r: CTRole) => r.roleId);
    }

    return res.data;
  }

  public async sessions(): Promise<Response<Session[]>> {
    const response = await Vue.axios.get<Response<Session[]>>(
      `${API_GATEWAY_ROOT}/sessions`
    );

    return response.data;
  }

  public async logoutAll(): Promise<void> {
    try {
      await Vue.axios.post(`${API_GATEWAY_ROOT}/logout-all`);
    } catch (error) {
      console.error(error);
    } finally {
      this.clearAuthentication();
    }

    this.logout();

    EventBus.emit('auth:logout', undefined);
  }

  public async changePassword(options: ChangePasswordOptions): Promise<void> {
    const response = await Vue.axios.post(
      `${API_GATEWAY_ROOT}/change-password`,
      options
    );

    return response.data;
  }

  public onlyAdmin(): boolean {
    return (this.getAuthenticatedUser()?.roles.includes(Roles.ADMIN))
      // && !this.getAuthenticatedUser()?.roles.includes('offline_access')
      // && !this.getAuthenticatedUser()?.roles.includes('uma_authorization')
      && !this.getAuthenticatedUser()?.roles.includes(Roles.RETAIL_CLERK)
      && !this.getAuthenticatedUser()?.roles.includes(Roles.MARKETING)
      && !this.getAuthenticatedUser()?.roles.includes(Roles.TECH)
      && !this.getAuthenticatedUser()?.roles.includes(Roles.TECH_SUPERVISOR)
      // && !this.getAuthenticatedUser()?.roles.includes('ME - Biddeford 1 Plant')
      // && !this.getAuthenticatedUser()?.roles.includes('ME - Oakland Plant')
      // && !this.getAuthenticatedUser()?.roles.includes('ME - South Portland Plant')
      // && !this.getAuthenticatedUser()?.roles.includes('NY - Scotia Plant')
      && !this.getAuthenticatedUser()?.roles.includes(Roles.PROCESS_MANAGER);
  }

  public onlyCustomerService(): boolean {
    return (this.getAuthenticatedUser()?.roles.includes(Roles.RETAIL_CLERK)
      || this.getAuthenticatedUser()?.roles.includes(Roles.TECH))
      && !this.getAuthenticatedUser()?.roles.includes(Roles.DEVELOPER)
      // && !this.getAuthenticatedUser()?.roles.includes('offline_access')
      // && !this.getAuthenticatedUser()?.roles.includes('uma_authorization')
      && !this.getAuthenticatedUser()?.roles.includes(Roles.MARKETING)
      // && !this.getAuthenticatedUser()?.roles.includes('ME - Biddeford 1 Plant')
      // && !this.getAuthenticatedUser()?.roles.includes('ME - Oakland Plant')
      // && !this.getAuthenticatedUser()?.roles.includes('ME - South Portland Plant')
      // && !this.getAuthenticatedUser()?.roles.includes('NY - Scotia Plant')
      && !this.getAuthenticatedUser()?.roles.includes(Roles.PROCESS_MANAGER)
      && !this.getAuthenticatedUser()?.roles.includes(Roles.ADMIN);
  }

  public onlyProcessing(): boolean {
    return ((this.getAuthenticatedUser()?.roles.includes(Roles.DEVELOPER)
      // || this.getAuthenticatedUser()?.roles.includes('ME - Biddeford 1 Plant')
      // || this.getAuthenticatedUser()?.roles.includes('ME - Oakland Plant')
      // || this.getAuthenticatedUser()?.roles.includes('ME - South Portland Plant')
      // || this.getAuthenticatedUser()?.roles.includes('NY - Scotia Plant')
      || this.getAuthenticatedUser()?.roles.includes(Roles.PROCESS_MANAGER))
      && !this.getAuthenticatedUser()?.roles.includes(Roles.RETAIL_CLERK)
      && !this.getAuthenticatedUser()?.roles.includes(Roles.RETAIL_SUPERVISOR)
      // && !this.getAuthenticatedUser()?.roles.includes('offline_access')
      // && !this.getAuthenticatedUser()?.roles.includes('uma_authorization')
      && !this.getAuthenticatedUser()?.roles.includes(Roles.MARKETING));
  }

  public onlyStaffing(): boolean {
    return (this.onlyProcessing()
      // && (this.getAuthenticatedUser()?.roles.includes('ME - Biddeford 1 Plant')
      //   || this.getAuthenticatedUser()?.roles.includes('ME - Oakland Plant')
      //   || this.getAuthenticatedUser()?.roles.includes('ME - South Portland Plant')
      //   || this.getAuthenticatedUser()?.roles.includes('NY - Scotia Plant'))
      && !this.getAuthenticatedUser()?.roles.includes(Roles.DEVELOPER)
      && !this.getAuthenticatedUser()?.roles.includes(Roles.PROCESS_MANAGER));
  }

  public onlyTrailerScans(): boolean {
    return (this.onlyProcessing()
      && (this.getAuthenticatedUser()?.roles.includes(Roles.DEVELOPER)
        || this.getAuthenticatedUser()?.roles.includes(Roles.PROCESS_MANAGER)));
    // && !this.getAuthenticatedUser()?.roles.includes('ME - Biddeford 1 Plant')
    // && !this.getAuthenticatedUser()?.roles.includes('ME - Oakland Plant')
    // && !this.getAuthenticatedUser()?.roles.includes('ME - South Portland Plant')
    // && !this.getAuthenticatedUser()?.roles.includes('NY - Scotia Plant'));
  }

  public hasStaffingAccess(): boolean {
    return this.getAuthenticatedUser()?.roles.includes(Roles.PROCESS_MANAGER);
    // return (this.getAuthenticatedUser()?.roles.includes('ME - Biddeford 1 Plant')
    //   || this.getAuthenticatedUser()?.roles.includes('ME - Oakland Plant')
    //   || this.getAuthenticatedUser()?.roles.includes('ME - South Portland Plant')
    //   || this.getAuthenticatedUser()?.roles.includes('NY - Scotia Plant'));
  }

  public hasTrailerScanAccess(): boolean {
    return (this.getAuthenticatedUser()?.roles.includes(Roles.DEVELOPER)
      || this.getAuthenticatedUser()?.roles.includes(Roles.PROCESS_MANAGER));
  }

  public hasCapacityUtilizationAccess(): boolean {
    return this.getAuthenticatedUser()?.roles.includes(Roles.PROCESS_MANAGER);
  }

  public hasCountingStationUsersAccess(): boolean {
    return this.getAuthenticatedUser()?.roles.includes(Roles.PROCESS_MANAGER);
  }

  public isTech(): boolean {
    return this.getAuthenticatedUser()?.roles.includes(Roles.TECH)
    || this.getAuthenticatedUser()?.roles.includes(Roles.TECH_SUPERVISOR);
  }

  public isSupTech(): boolean {
    return this.getAuthenticatedUser()?.roles.includes(Roles.TECH_SUPERVISOR);
  }

  private disableAuthHeader(): void {
    if (this.interceptor !== undefined) {
      Vue.axios.interceptors.request.eject(this.interceptor);
      this.interceptor = undefined;
    }
  }

  private clearAuthentication() {
    this.disableAuthHeader();

    this.state.user = null;
    this.state.token = '';
    this.state.identity = '';
    this.state.authenticated = false;
  }

  private interceptor: number | undefined;

  private readonly state = Vue.observable<AuthState>({
    token: '',
    user: null,
    identity: '',
    authenticated: false
  });
}

export default new AuthService();
