<template>
  <v-dialog
    :value="value"
    max-width="450"
    :persistent="isSubmitting"
    @input="$emit('input', $event)"
  >
    <v-card>
      <v-card-title>Change Password</v-card-title>

      <v-card-text>
        <v-container>
          <span class='text-body-2'>
            <span class='red--text'>*</span> Changing your password will
            log you out of all sessions including your current one. If you
            have any unsaved work you do not wish to lose, please save it
            before continuing.
          </span>

          <v-form
            ref="form"
            v-model="isFormValid"
            @submit.stop.prevent="submit"
          >
            <v-text-field
              v-for="id in fields"
              :key="id"
              :name="id"
              :label="form[id].label"
              :error-messages="form[id].error"
              :rules="[ (value) => form[id].validate(form, value) ]"
              :type="form[id].show ? 'text' : 'password'"
              :ref="id"
              v-model="form[id].value"
              :append-icon="form[id].show ? 'mdi-eye' : 'mdi-eye-off'"
              @input="touchField(form[id])"
              @click:append="form[id].show = !form[id].show"
            />

            <input
              type="submit"
              style="display:none"
            />
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-btn
          text
          color="primary"
          :disabled="isSubmitting"
          @click="close"
        >
          Close
        </v-btn>

        <v-btn
          text
          color="primary"
          :disabled="isSubmitting || !isFormValid"
          @click="submit"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang='ts'>
import Vue from 'vue';
import Toastr from 'toastr';
import AuthService from '@/services/authService';

const Fields = ['old', 'new', 'confirm'] as const;

interface Field {
  value: string | null;
  show: boolean;
  dirty: boolean;
  error: string;
  label: string;
  validate: (form: Record<typeof Fields[number], Field>, value: string | null) => true | string;
}

type Form = Record<typeof Fields[number], Field>;

const createForm = (): Form => ({
  old: {
    value: '',
    show: false,
    dirty: false,
    label: 'Old Password',
    error: '',
    validate(form: Form, value: string | null): true | string {
      if (value === null || value.length === 0) {
        return 'This field is required';
      }

      return true;
    },
  },
  new: {
    value: '',
    show: false,
    dirty: false,
    label: 'New Password',
    error: '',
    validate(form: Form, value: string | null): true | string {
      if (value === null || value.length === 0) {
        return 'This field is required';
      }

      if (value.length < 6) {
        return 'Must be at least 6 characters';
      }

      if (value === form.old.value) {
        return 'Can\'t be the same as your old password';
      }

      if (!/[a-zA-Z]/.test(value)) {
        return 'Must contain at least one character';
      }

      if (!/[0-9]/.test(value)) {
        return 'Must contain at least one digit';
      }

      return true;
    },
  },
  confirm: {
    value: '',
    show: false,
    dirty: false,
    label: 'Confirm Password',
    error: '',
    validate(form: Form, value: string | null): true | string {
      if (value === null || value.length === 0) {
        return 'This field is required';
      }

      return (value === form.new.value) || 'Must match the new password field';
    }
  },
});

export default Vue.extend({
  name: 'ChangePassword',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      fields: Fields,
      isFormValid: false,
      form: createForm(),
      isSubmitting: false,
    };
  },
  methods: {
    touchField(field: Field) {
      // eslint-disable-next-line no-param-reassign
      field.dirty = true;

      // eslint-disable-next-line no-param-reassign
      field.error = '';

      // Vuetify will only run the validation for the field
      // that changed. However, the form here has a few dependent
      // fields (Confirm Password must match New Password and
      // New Password cannot equal Old Password), so we need
      // to validate any modified fields.

      Fields.forEach((id) => {
        if (this.form[id] === field || !this.form[id].dirty) {
          // Vue will automatically validate a form on @input,
          // so we skip the unecessary extra validation in that case.

          // We also don't validate a field if it hasn't been
          // modified yet.

          return;
        }

        let ref = this.$refs[id];

        if (Array.isArray(ref)) {
          [ref] = ref;
        }

        if (ref !== undefined) {
          const el = (ref as unknown) as { validate: () => void };

          el.validate();
        }
      });
    },
    close() {
      this.$emit('input', false);
    },
    async submit() {
      if (this.isSubmitting) {
        return;
      }

      const { form } = this.$refs;

      if (form === undefined) {
        return;
      }

      const el = (form as unknown) as { validate: () => boolean };

      const valid = el.validate();

      if (!valid || Fields.some((id) => this.form[id].error !== '')) {
        return;
      }

      this.isSubmitting = true;

      try {
        await AuthService.changePassword({
          oldPassword: this.form.old.value ?? '',
          newPassword: this.form.new.value ?? '',
        });

        Toastr.success('Your password has been updated');

        this.close();

        try {
          await AuthService.logoutAll();
        } catch (error) {
          console.error(error);
        }
      } catch (error) {
        let generic = true;

        if (this.axios.isAxiosError(error)) {
          const data = error.response?.data;

          if (data !== null && typeof data === 'object') {
            if (data.status === 'fail') {
              if (data.data?.message === 'Incorrect password') {
                this.form.old.error = 'Incorrect password';

                generic = false;
              }
            }
          }
        }

        if (generic) {
          Toastr.error('An error occured while updating your password');
        }
      } finally {
        this.isSubmitting = false;
      }
    },
  },
  watch: {
    value(value: boolean) {
      if (!value) {
        const { form } = this.$refs;

        if (form !== undefined) {
          const el = (form as unknown) as { reset: () => void };

          el.reset();
        }

        Fields.forEach((id) => {
          const field = this.form[id];

          field.dirty = false;
          field.error = '';
        });
      }
    }
  }
});
</script>
