import Vue from 'vue';
import Axios, { AxiosResponse } from 'axios';

type TEndpointTypes = {
  RequestPathParams?: undefined | unknown;
  RequestQueryParams?: undefined | unknown;
  RequestBody?: undefined | unknown;
  ResponseBody: unknown;
};

type TEndpointRequestTypes<T extends Record<string, unknown>> = Omit<T, 'ResponseBody'>;

interface IPathConfig {
  basePath: string;
  path: string;
}

interface IEndpointConfig<T extends TEndpointTypes> {
  method: 'get' | 'put' | 'post' | 'delete';
  noWrap?: boolean;
  configurePath(reqConfig: TEndpointRequestTypes<T>): IPathConfig;
}

const ObservableRequest = class <T extends TEndpointTypes> {
  #config: IEndpointConfig<T>;

  #states = Vue.observable({
    data: undefined,
    latestError: undefined,
    isLoading: false
  } as {
    data: T['ResponseBody'] | undefined;
    latestError: Error | string | undefined;
    isLoading: boolean;
  });

  #currentAbortController: AbortController | undefined = undefined;

  #currentRequest: Promise<AxiosResponse<unknown, unknown>> | undefined = undefined;

  get states() {
    return { ...this.#states };
  }

  get data() {
    return this.#states.data;
  }

  get isLoading() {
    return this.#states.isLoading;
  }

  get error() {
    return this.#states.latestError;
  }

  constructor(config: IEndpointConfig<T>) {
    this.#config = config;
  }

  async request<X extends TEndpointRequestTypes<T>>(requestInfo: X) {
    type TResponseBody = T['ResponseBody'];

    const { RequestBody, RequestQueryParams } = requestInfo;
    const { configurePath, method, noWrap } = this.#config;

    try {
      if (method !== 'get') {
        // these types of requests must be finished before starting a
        // new request
        if (this.isLoading) {
          throw new Error(
            'Action disallowed: A request is already in progress'
          );
        }

        if (!RequestBody) {
          throw new Error(
            'This type of request requires sending some type of data in its payload'
          );
        }
      }

      if (method === 'get') {
        // if a request of this type is already pending, abort the
        // prior request before starting a new request
        if (this.isLoading && this.#currentAbortController) {
          // trigger an abort signal
          this.#currentAbortController.abort();
        } else if (this.isLoading) {
          // if for some reason we don't have an abortController
          // just throw an error
          throw new Error('A request is pending but no AbortController is available to abort it.');
        }

        if (typeof RequestBody !== 'undefined') {
          throw new Error(
            'This type of request does not support sending payload data. Consider using `post` or `put` instead.'
          );
        }
      }

      // create new AbortController
      this.#currentAbortController = new AbortController();

      // signal that we are busy
      this.#states.isLoading = true;

      // reset latest error
      this.#states.latestError = undefined;

      // re-configure the paths based on data, metadata, and params
      const { basePath, path } = configurePath(requestInfo);

      if (basePath.endsWith('/') && path.startsWith('/')) {
        throw new Error(
          'The `basePath` ends with a `/` and the `path` starts with `/`. One of these characters should be removed.'
        );
      }

      // make the request
      this.#currentRequest = Axios.request<TResponseBody>({
        baseURL: basePath,
        url: path,
        method,
        data: RequestBody,
        params: RequestQueryParams,
        signal: this.#currentAbortController.signal
      }).catch(async (reason) => {
        // if this promise is rejected because its request was aborted
        // then just return the currentRequest if there is one. Otherwise,
        // throw the reason.
        if (reason?.message === 'canceled') {
          if (this.#currentRequest) {
            return this.#currentRequest;
          }
        }

        throw reason;
      });

      const response = (await this.#currentRequest) as AxiosResponse<TResponseBody & { data?: TResponseBody }, unknown>;
      const responseBody = response.data;

      // set the data
      this.#states.data = noWrap ? responseBody : responseBody.data;
    } catch (error) {
      // if an error occurs, save the error
      if (error instanceof Error) {
        this.#states.latestError = error;
      } else {
        this.#states.latestError = new Error(String(error));
      }

      // expose error to console
      console.error('APIHandler', this.error);
    }

    // signal that we are no longer loading
    this.#states.isLoading = false;

    // Looks like we have some cases of using APIHandler that don't
    // expect the promise to be rejected. This means the code in question
    // is probably relying on `instance.error` to handle any errors.
    //
    // Leaving this commented out for now.
    // return Promise.reject(instance.#states.latestError);

    return this;
  }

  clear() {
    if (this.isLoading) {
      throw new Error('Clearing while loading is currently unsupported');
    }

    this.#states.latestError = undefined;
    this.#states.data = undefined;
  }
};

// eslint-disable-next-line import/prefer-default-export, @typescript-eslint/explicit-module-boundary-types
export const defineAPI = <T extends TEndpointTypes>(config: IEndpointConfig<T>) => ({
  asObservable() {
    return Vue.observable(new ObservableRequest(config));
  }
});
