import {
  defineGetUserSettingsByUserId, defineSaveUserSettingsByUserId, IUserSettings, defaultUserSettings
} from '@/services/userSettingsService';
import AuthService from '@/services/authService';
import EventBus from '@/services/common/EventBus';

class CurrentUserSettingsService {
  // eslint-disable-next-line class-methods-use-this
  get #currentUser() {
    return AuthService.getAuthenticatedUser();
  }

  readonly requests = {
    getUserSettings: defineGetUserSettingsByUserId.asObservable(),
    updateUserSettings: defineSaveUserSettingsByUserId.asObservable()
  };

  constructor() {
    this.refresh();
  }

  public get settings() {
    const defaults = defaultUserSettings();

    if (!AuthService.isAuthenticated()) {
      return defaults;
    }

    if (!this.isLoading && !this.requests.getUserSettings.data) {
      return defaults;
    }

    return this.requests.getUserSettings.data || defaults;
  }

  public get isLoading() {
    return this.requests.getUserSettings.states.isLoading;
  }

  public get isSaving() {
    return this.requests.updateUserSettings.states.isLoading;
  }

  public get isBusy() {
    return this.isLoading || this.isSaving;
  }

  public async refresh(): Promise<void> {
    if (!AuthService.isAuthenticated()) {
      return;
    }

    await this.requests.getUserSettings.request({ RequestPathParams: { userid: this.#currentUser.legacyUserId } });
  }

  public async update(settings: IUserSettings) {
    const defaultSettings = defaultUserSettings();
    const currentSettings = this.settings;
    const { states } = this.requests.updateUserSettings;

    if (states.isLoading) {
      return;
    }

    if (typeof currentSettings?.LegacyUserId === 'undefined') {
      throw new Error('LegacyUserId is required when updating a users Settings');
    }

    await this.requests.updateUserSettings.request({
      RequestBody: {
        UserSettingsId: currentSettings?.UserSettingsId || defaultSettings.UserSettingsId,
        LegacyUserId: currentSettings.LegacyUserId,
        Settings: settings,

        CreatedByUserId: currentSettings?.CreatedByUserId || defaultSettings.CreatedByUserId,
        CreatedDate: currentSettings?.CreatedDate,

        ModifiedByUserId: currentSettings?.ModifiedByUserId || defaultSettings.ModifiedByUserId,
        ModifiedDate: currentSettings?.ModifiedDate || defaultSettings.ModifiedDate
      },

      RequestPathParams: { userid: this.#currentUser.legacyUserId }
    });
  }
}

const instance = new CurrentUserSettingsService();

// handle login event
EventBus.on('auth:login', () => {
  instance.refresh();
});

// handle logout event
EventBus.on('auth:logout', () => {
  instance.refresh();
});

export default instance;
