import Vue from 'vue';
import Axios from 'axios';
import VueAxios from 'vue-axios';
import Vidle from 'v-idle';
import VueMask from 'v-mask';
import VueKeyCloak from '@dsb-norge/vue-keycloak-js';
import vuetify from './plugins/vuetify';
import router from './router';
import App from './App.vue';
import AuthService from './services/authService';
import { config } from './services/common/RuntimeConfig';

Vue.config.productionTip = false;

Axios.defaults.withCredentials = true;
Vue.use(VueAxios, Axios);

Vue.use(Vidle);
Vue.use(VueMask);

Vue.use(VueKeyCloak, {
  config: {
    url: config.VUE_APP_KEYCLOAK_URL || process.env.VUE_APP_KEYCLOAK_URL,
    realm: config.VUE_APP_KEYCLOAK_REALM || process.env.VUE_APP_KEYCLOAK_REALM,
    clientId: config.VUE_APP_KEYCLOAK_CLIENT_ID || process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
  },
  init: {
    onLoad: 'check-sso',
  },
  updateInterval: 60000,
  onReady: async (keycloak) => {
    const { authenticated } = keycloak;

    if (authenticated) {
      const authToken = keycloak.token as string;
      const identityToken = keycloak.idToken as string;

      await AuthService.storeUser(authToken, identityToken, authenticated);
    }

    /* eslint-disable no-new */
    new Vue({
      el: '#app',
      router,
      vuetify,
      components: { App },
      template: '<App />'
    });
  },
  onAuthRefreshSuccess: async (keycloak) => {
    const { authenticated } = keycloak;

    if (authenticated) {
      const authToken = keycloak.token as string;
      const identityToken = keycloak.idToken as string;

      await AuthService.refresh(authToken, identityToken, authenticated);
    }
  },
  onAuthRefreshError: () => {
    console.error('refresh failed');
  },
  onInitError: (error, err) => {
    console.error(error);
    console.error(err);
  },
});
