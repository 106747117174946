export type VuetifyFormMethods = {
  validate(): boolean;
  reset(): void;
  resetValidation(): void;
};

type TFormFieldRule<T> = (value: T) => true | string;

export type TFormField<T = undefined> = {
  originalValue: T | undefined | null;
  value: T | undefined | null;
  rules: TFormFieldRule<T | undefined | null>[];

  readonly isDirty: boolean;
  init: (value: T | undefined) => void
};

export const createField = <T>(originalValue: T | undefined, currentValue = originalValue, rules: TFormField<T>['rules'] = []): TFormField<T> => {
  const field: TFormField<T> = {
    originalValue,
    value: currentValue,
    rules,

    get isDirty() {
      return this.value !== this.originalValue;
    },

    init(value) {
      this.value = value;
      this.originalValue = value;
    }

  };

  return field;
};
