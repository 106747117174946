import mitt from 'mitt';

/*
  We could go with the usual "Event Bus" method by using something like:

    const EventBus = new Vue();

  ...but Vue recommends a different approach;

  See https://github.com/vuejs/rfcs/blob/master/active-rfcs/0020-events-api-change.md

  With this in mind, and based on Vue's recommendation, this is using a third party
  libray to create and maintain an "Event Bus".
*/

type TKnownEvents = {
  // specify known events here, for types!
  'auth:login': undefined;
  'auth:logout': undefined;

  // this adds support for any events that
  // weren't defined in the space above.
  // the downnside to this is that events
  // are given a TEvent<unknown> type.
  [key: string]: unknown | undefined;
};

export type TEvent<D = undefined> = {
  name: string;
  data: D;
  metadata: {
    emittedDateTime: Date
  }
};

type TWrappedEvents = {
  [K in keyof TKnownEvents]: TEvent<TKnownEvents[K]>;
};

const bus = mitt<TWrappedEvents>();

export default {
  get on(): typeof bus.on {
    return bus.on;
  },

  get off(): typeof bus.off {
    return bus.off;
  },

  emit<N extends keyof TKnownEvents, D extends TKnownEvents[N]>(name: N, data: D): void {
    const event: TEvent<D> = {
      name: name as string, data, metadata: { emittedDateTime: new Date() }
    };

    return bus.emit(name, event);
  },

  /**
   * Clears all event listeners
   */
  clear(): ReturnType<typeof bus.all.clear> {
    return bus.all.clear();
  }
};
