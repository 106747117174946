<template>
  <v-card>
    <v-card-title>{{ user === null ? 'N/A' : user.fullname }}</v-card-title>
    <v-card-subtitle>{{ user === null ? 'N/A' : user.username }}</v-card-subtitle>

    <v-card-text>
      <v-list dense>
        <v-subheader>ROLES</v-subheader>

        <div v-if="roles.length > 0">
          <v-list-item
            dense
            :key="role"
            v-for="role in rolesToShow"
          >
            <v-list-item-title>{{ role }}</v-list-item-title>
          </v-list-item>

          <v-tooltip
            bottom
            v-if="roles.length > maxRolesToShow"
          >
            <template #activator="{ on, attrs }">
              <v-list-item
                dense
                v-on="on"
                v-bind="attrs"
              >
                <v-list-item-title>(+{{ (roles.length - maxRolesToShow).toLocaleString() }}) more</v-list-item-title>
              </v-list-item>
            </template>

            <span>{{ extraRolesToShow.join(', ') }}</span>
          </v-tooltip>
        </div>

        <div v-else>
          <v-list-item dense>
            <v-list-item-title>No roles assigned</v-list-item-title>
          </v-list-item>
        </div>
      </v-list>
    </v-card-text>

    <v-card-actions>
      <v-menu
        offset-x
        left
        transition="slide-x-reverse-transition"
        :open-on-hover="true"
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            v-bind="attrs"
            v-on="on"
          >
            Account Actions
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="(action) in actions"
            :key="action.id"
            @click.stop="onAccountAction(action)"
          >
            <v-list-item-title>{{ action.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-actions>

    <ChangePassword v-model="showChangePasswordDialog" />
  </v-card>
</template>

<script lang='ts'>
import Vue from 'vue';
import AuthService, { User } from '@/services/authService';
import ChangePassword from '@/Layout/Components/Dialogs/ChangePassword.vue';
import { CTRole } from '@/types/ct-users-api-types';

type TAction = { id: string; title: string; };

interface IData {
  showChangePasswordDialog: boolean;

  maxRolesToShow: number;

  showAccountMenu: boolean;

  roles: string[];
}

export default Vue.extend({
  name: 'AccountMenu',
  components: {
    ChangePassword,
  },

  computed: {
    user(): User {
      return AuthService.getAuthenticatedUser();
    },

    rolesToShow(): string[] {
      return this.roles.slice(0, this.maxRolesToShow);
    },

    extraRolesToShow(): string[] {
      return this.roles.slice(this.rolesToShow.length, this.roles.length);
    },

    actions(): TAction[] {
      return [
        { id: 'change-password', title: 'Change Password' },
      ];
    }
  },

  data(): IData {
    return {
      showChangePasswordDialog: false,
      maxRolesToShow: 4,
      showAccountMenu: false,
      roles: []
    };
  },

  methods: {
    async onAccountAction(action: TAction) {
      if (action.id === 'change-password') {
        this.showChangePasswordDialog = true;
      }

      this.showAccountMenu = false;
    },
  },

  async mounted() {
    const data = await AuthService.loadRoles();

    this.roles = data.map((r: CTRole) => r.name);
  }
});
</script>
