import { defineAPI } from '@/services/common/APIHandler';
import { createField, TFormField } from '@/types/form-types';
import { config } from './common/RuntimeConfig';

const root = process.env.VUE_APP_API_SERVICE_USER_SETTINGS_ROOT ?? (process.env.VUE_APP_API_GATEWAY || config.VUE_APP_API_GATEWAY);
const base = 'user-settings/legacy';

// The system color scheme will now affect the default color theme
// (`Dark` vs `Light`). User settings may still override this.
const systemColorScheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'Dark' : 'Light';

export interface IUserSettings {
  general: {
    theme: 'Light' | 'Dark' | undefined | null;
  };

  printing: {
    labels: {
      defaultLabelPrinter: string | undefined | null;
      preferredSubscriptionLabelPrinter: string | undefined | null;
    };
  };
}

export interface IUserSettingsRecord {
  UserSettingsId: number;
  LegacyUserId: number;

  Settings: IUserSettings;

  CreatedDate: Date;
  CreatedByUserId: string;

  ModifiedDate: Date;
  ModifiedByUserId: string;
}

export interface IUserSettingsFormFields {
  general: {
    theme: TFormField<IUserSettings['general']['theme']>;
  };

  printing: {
    labels: {
      defaultLabelPrinter: TFormField<
        IUserSettings['printing']['labels']['defaultLabelPrinter']
      >;
      preferredSubscriptionLabelPrinter: TFormField<
        IUserSettings['printing']['labels']['preferredSubscriptionLabelPrinter']
      >;
    };
  };
}

export const defaultUserSettings = (): IUserSettingsRecord => ({
  UserSettingsId: -1,
  LegacyUserId: -1,

  Settings: {
    general: {
      theme: systemColorScheme
    },

    printing: {
      labels: {
        defaultLabelPrinter: undefined,
        preferredSubscriptionLabelPrinter: undefined
      }
    }
  },

  CreatedByUserId: '-1',
  CreatedDate: new Date(),

  ModifiedByUserId: '-1',
  ModifiedDate: new Date()
});

export const defaultUserSettingsFormFields = (): IUserSettingsFormFields => ({
  general: {
    theme: createField<IUserSettings['general']['theme']>(
      undefined,
      undefined,
      []
    )
  },

  printing: {
    labels: {
      defaultLabelPrinter: createField<
        IUserSettings['printing']['labels']['defaultLabelPrinter']
      >(undefined, undefined, []),
      preferredSubscriptionLabelPrinter: createField<
        IUserSettings['printing']['labels']['preferredSubscriptionLabelPrinter']
      >(undefined, undefined, [])
    }
  }
});

export const defineGetUserSettingsByUserId = defineAPI<{
  RequestPathParams: { userid: number };
  ResponseBody: IUserSettingsRecord;
}>({
  method: 'get',
  configurePath: (req) => ({
    basePath: `${root}/${base}`,
    path: `/${req.RequestPathParams.userid}`
  })
});

export const defineSaveUserSettingsByUserId = defineAPI<{
  RequestPathParams: { userid: number };
  RequestBody: IUserSettingsRecord;
  ResponseBody: IUserSettingsRecord;
}>({
  method: 'put',
  configurePath: (req) => ({
    basePath: `${root}/${base}`,
    path: `/${req.RequestPathParams.userid}`
  })
});
