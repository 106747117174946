/* eslint-disable import/prefer-default-export */
import AuthService, { Roles } from './authService';

// const isProd = process.env.NODE_ENV === 'production';

type TItemOptions = {
  show: boolean;
};

const defaultItemOptions = {
  show: true
};

const createItem = <A extends string, B extends string, C extends string>(title: A, route: B, icon?: C, opts: Partial<TItemOptions> = {}) => ({
  title, route, icon, options: { ...defaultItemOptions, ...opts }
});

type TGroupOptions = {
  show: boolean;
};

const defaultGroupOptions = {
  show: true
};

const createGroup = <A extends string, B extends (ReturnType<typeof createItem>)[]>(name: A, items: B, opts: Partial<TGroupOptions> = {}) => {
  const group = {
    name, items, options: { ...defaultGroupOptions, ...opts }
  };

  return group;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createInstance = () => {
  const user = AuthService.getAuthenticatedUser();

  return [
    createGroup('Portal', [
      createItem('Dashboard', '/my-portal/home', 'mdi-monitor-dashboard', { show: !AuthService.onlyCustomerService() && !AuthService.onlyAdmin() }),
      createItem('Sessions', '/my-portal/sessions', 'mdi-ip-network-outline'),
      createItem('Settings', '/my-portal/settings', 'mdi-cog-outline'),
      createItem('Logout', '/my-portal/logout?uaction=1', 'mdi-logout-variant')
    ]),

    createGroup('Admin', [
      createItem('Home', '/my-portal/admin/home', 'mdi-home'),
      createItem('Users', '/my-portal/admin/users', 'mdi-account-group')
    ], { show: user.roles.some((r) => r === Roles.ADMIN) }),

    createGroup('Customer Service', [
      createItem('Home', '/my-portal/customer-service/home', 'mdi-home'),
      createItem('Customers', '/my-portal/customer-service/customers', 'mdi-account-search'),
      //  createItem('Onboarding', '/my-portal/customer-service/customer-onboarding', 'mdi-stairs', { show: !isProd }),
      createItem('Account Adjustments', '/my-portal/customer-service/account-adjustment-requests', 'mdi-bank-transfer'),
      //    createItem('Bag Donation Events', '/my-portal/customer-service/bag-donation-events', 'mdi-account-search-outline')
    ], { show: user.roles.some((r) => r === Roles.RETAIL_CLERK || r === Roles.RETAIL_SUPERVISOR) }),

    // createGroup('Marketing', [
    //   createItem('Bag Donation Events', '/my-portal/marketing/bag-donation-events', 'mdi-account-search-outline')
    // ], { show: user.roles.some((r) => r === 'marketing') }),

    // createGroup('Processing', [
    //   createItem('Home', '/my-portal/processing/home', 'mdi-home'),
    // createItem('Trailer Scans', '/my-portal/processing/trailer-scans', 'mdi-truck', {
    //   show: !isProd && user.roles.some((r) => r === 'process_manager'),
    // }),
    // createItem('Staffing', '/my-portal/processing/staffing', 'mdi-calendar-account', {
    //   show: !isProd && (user.roles.includes('ME - Biddeford 1 Plant')
    //     || user.roles.includes('ME - Oakland Plant')
    //     || user.roles.includes('ME - South Portland Plant')
    //     || user.roles.includes('NY - Scotia Plant'))
    // }),
    // createItem('Capacity Utilization', '/my-portal/processing/capacity-utilization', 'mdi-truck', {
    //   show: !isProd && user.roles.includes('process_manager')
    // }),
    // createItem('Counting Station Users', '/my-portal/processing/counting-station-users', 'mdi-sack', {
    //   show: user.roles.includes('process_manager')
    // })
    // ], {
    //   show: user.roles.includes(Roles.PROCESS_MANAGER)

    // || user.roles.includes('ME - Biddeford 1 Plant')
    // || user.roles.includes('ME - Oakland Plant')
    // || user.roles.includes('ME - South Portland Plant')
    // || user.roles.includes('NY - Scotia Plant')
    // }),
    createGroup('Retail', [
      createItem('Home', '/my-portal/retail/home', 'mdi-home'),
      createItem('Depots', '/my-portal/retail/depots', 'mdi-storefront-outline'),
      //   createItem('Manual Entry', '/my-portal/retail/manual-entry', 'mdi-view-list'),
      //   createItem('User Management', '/my-portal/retail/user-management', 'mdi-account-multiple')
    ], { show: user.roles.some((r) => r === Roles.RETAIL_CLERK || r === Roles.RETAIL_SUPERVISOR || r === Roles.TECH || r === Roles.TECH_SUPERVISOR) }),
  ] as const;
};
