<template>
  <div id="app">
    <BaseLayout>
      <keep-alive include="CustomerSearch">
        <router-view />
      </keep-alive>
    </BaseLayout>

    <idle-timer
      :onExpire="onExpire"
      :onSecond="onSecond"
    />
  </div>
</template>

<script lang='ts'>
import Vue from 'vue';
import Timer from '@/services/common/Timer';
import IdleTimer from '@/Layout/Components/IdleTimer.vue';
import AuthService from '@/services/authService';
import BaseLayout from '@/Layout/Wrappers/baseLayout.vue';

export default Vue.extend({
  components: {
    BaseLayout,
    IdleTimer
  },

  data() {
    return {
      onExpire: () => {
        AuthService.logout();
      },

      onSecond: (x: Timer) => {
        this.$data.millisecondsLeft = x.getTimeLeft();
      },
    };
  },

  created() {
    this.$http.interceptors.response.use(undefined, (err) => new Promise((resolve, reject) => {
      // eslint-disable-next-line no-underscore-dangle
      if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
        console.warn(resolve, reject);
      }

      throw err;
    }));
  }
});
</script>

<style lang="scss">
@import "./styles/global-styles.scss";
@import "./styles/toastr.scss";
</style>
