import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'material-design-icons-iconfont/dist/material-design-icons.css';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#9252ab',
        secondary: '#2a3754',
        accent: '#794c8a',
        error: '#d92550',
        info: '#78C3FB',
        success: '#3ac47d',
        warning: '#f7b924',
      },
      dark: {
        primary: '#9252ab',
        secondary: '#2a3754',

      }
    },
  },
  default: {
    primary: '#9252ab',
    secondary: '#2a3754',
    accent: '#794c8a',
    error: '#d92550',
    info: '#78C3FB',
    success: '#3ac47d',
    warning: '#f7b924',
  },
  options: {
    customProperties: true,
  },
  iconfont: 'md' || 'mdi',
});
