interface IEventEntry<T> {
  name: string;

  value: T | undefined | null;

  dateLastSet: string | null;
}

type TonEventDetail = { entry: IEventEntry<unknown> };
type TCustomEvent = CustomEvent<TonEventDetail>;
type TonEvent<T> = (type: T, listener: (evt: TCustomEvent) => void, options?: boolean | AddEventListenerOptions) => void;
type TEventNames = `${'After'}${'Get' | 'Set' | 'Remove' | 'Clear'}`;
type TOn = TonEvent<TEventNames>;

const eventTarget = new EventTarget();

const dispatchEvent = (name: TEventNames, entry: IEventEntry<unknown>): boolean => {
  // we need to force a specific `this` value for the dispatchEvent function
  const fn = eventTarget.dispatchEvent.bind(globalThis);

  return fn(new CustomEvent<TonEventDetail>(name, {
    cancelable: true,
    detail: { entry }
  }));
};

export const addEventListener = eventTarget.addEventListener.bind(globalThis) as TOn;
export const removeEventListener = eventTarget.removeEventListener.bind(globalThis) as TOn;

export const getItem = <T>(name: string): IEventEntry<T> => {
  // create a default response object in case we need it later
  const defaultResponse = { name, value: undefined, dateLastSet: null };

  // attempt to get data from sessionStorage
  const sessionValue = sessionStorage.getItem(name);

  // either parse the sessionValue or use a defaultResponse
  // !: this could throw an error if the value cannot be parsed as JSON
  const response = sessionValue ? JSON.parse(sessionValue) as IEventEntry<T> : defaultResponse;

  // dispatch an event
  const shouldContinue = dispatchEvent('AfterGet', response);

  // if a handler of the dispatched event told us to preventDefault()
  if (!shouldContinue) {
    return defaultResponse;
  }

  return response;
};

export const setItem = <T>(name: string, value: T | undefined | null): void => {
  // create the object entry
  const objEntry: TonEventDetail['entry'] = {
    name,
    value,

    dateLastSet: new Date().toISOString()
  };

  // parse objEntry as JSON
  // !: this could throw an error if the object entry cannot be parsed as JSON
  const valueToUse = JSON.stringify(objEntry as IEventEntry<T>);

  // attempt to save data to sessionStorage
  sessionStorage.setItem(name, valueToUse);

  // dispatch an event
  dispatchEvent('AfterSet', objEntry);
};

export const removeItem = (name: string): void => {
  sessionStorage.removeItem(name);

  // create a mock object entry
  const objEntry: TonEventDetail['entry'] = {
    name,
    value: undefined,
    dateLastSet: null
  };

  // dispatch an event
  dispatchEvent('AfterRemove', objEntry);
};

// listen for storage events triggered from other windows or browser tabs
// note: this does not trigger when changes are made in the same instance that the change was made

type TModdedGlobal = typeof globalThis & { storageEventListener?: Parameters<typeof globalThis['addEventListener']>[1] };
const ModdedGlobal = globalThis as TModdedGlobal;

if (ModdedGlobal.storageEventListener) {
  globalThis.removeEventListener('storage', ModdedGlobal.storageEventListener);
}

ModdedGlobal.storageEventListener = (event) => {
  if (!(event instanceof StorageEvent)) {
    return;
  }

  const {
    key, oldValue, newValue, storageArea
  } = event;

  // ignore events for anything other than sessionStorage (like localStorage)
  if (storageArea !== sessionStorage) {
    return;
  }

  // shortcut to create a mock object entry
  const createMockEntry = (name: TonEventDetail['entry']['name'], value?: TonEventDetail['entry']['value']): TonEventDetail['entry'] => ({
    name,
    value,

    dateLastSet: null
  });

  // storage was likely cleared
  if (key === null && oldValue === null && newValue === null) {
    dispatchEvent('AfterClear', createMockEntry('ALL'));
    return;
  }

  let parsedOld: unknown;

  try {
    parsedOld = JSON.parse(newValue || '') as IEventEntry<unknown>;
    // eslint-disable-next-line no-empty
  } catch { }

  if (!parsedOld || !['name', 'value'].every((e) => Object.getOwnPropertyNames(parsedOld).includes(e))) {
    // the storage item is not supported by SessionStorageManager
    return;
  }

  const parsedOldTyped = parsedOld as IEventEntry<unknown>;

  // specific key was likely removed
  if (key !== null && oldValue !== null && newValue === null) {
    dispatchEvent('AfterRemove', createMockEntry(key, undefined));
    return;
  }

  let parsedNew: unknown;

  try {
    parsedNew = JSON.parse(newValue || '') as IEventEntry<unknown>;
    // eslint-disable-next-line no-empty
  } catch { }

  if (!parsedNew || !['name', 'value'].every((e) => Object.getOwnPropertyNames(parsedNew).includes(e))) {
    // the storage item is not supported by SessionStorageManager
    return;
  }

  const parsedNewTyped = parsedNew as IEventEntry<unknown>;

  // specific key was likely added
  if (key !== null && oldValue === null && newValue !== null) {
    dispatchEvent('AfterSet', createMockEntry(key, parsedNewTyped.value));
    return;
  }

  // specific key was likely updated
  if (key !== null && parsedOldTyped.value !== parsedNewTyped.value) {
    dispatchEvent('AfterSet', createMockEntry(key, parsedNewTyped.value));
    return;
  }

  console.info('SessionStorageManager: a storage event not handledd', event);
};

// not ready to handle this just yet, need to find a way to remove this event
// listener if it already exists; especially during `vue-cli-service serve`
// ModdedGlobal.addEventListener('storage', ModdedGlobal.storageEventListener);
