import Vue from 'vue';
import { getItem, setItem } from '@/services/common/SessionStorageManager';
import { Customer } from '@/types/customer-api-types';

export const routeBase = 'customer-';

const createSubtab = <K extends string, T extends string>(key: K, title: T) => Object.freeze({ key, title, routeName: `${routeBase}${key}` } as const);

export const SubTabs = [
  createSubtab('account', 'Account'),
  createSubtab('profile', 'Profile'),
  createSubtab('cards', 'Cards'),
  // createSubtab('online', 'Online'),
  createSubtab('baghistory', 'Bags'),
  // createSubtab('security', 'Security'),
  createSubtab('transactions', 'Transactions'),
  createSubtab('adjustments', 'Account Adjustments'),
  // createSubtab('subscriptions', 'Subscriptions'),
  createSubtab('notes', 'Notes'),
] as const;

export type TSubTab = (typeof SubTabs)[number];
export type TSubTabKey = (typeof SubTabs)[number]['key'];

export const defaultSubTabKey: TSubTabKey = 'profile';

export const getSubTabByKey = (
  key?: string | undefined | null
): TSubTab | undefined => {
  if (!key) {
    return undefined;
  }

  return SubTabs.find((st) => st.key === key);
};

export const getSubTabByRouteName = (
  key?: string | undefined | null
): TSubTab | undefined => {
  if (!key) {
    return undefined;
  }

  return getSubTabByKey(key.replace(routeBase, ''));
};

export interface ISessionCustomer {
  id?: Customer['id'];
  accountId?: Customer['accountId'];
  firstName?: Customer['firstName'];
  lastName?: Customer['lastName'];
  cardNumber?: Customer['cardNumber'];
  status?: Customer['status'];
  type?: Customer['type'];

  lastSubTabKey?: TSubTabKey;

  sessionCreateDate?: string;

  identityConfirmedDate?: string | null;
}

/*
  For a general understanding of how this is supposed to be used,
  see https://austincooper.dev/2019/08/09/vue-observable-state-store/
*/

const state = Vue.observable({
  sessionCustomers: [] as ISessionCustomer[],

  lastOpenedSessionCustomerId: undefined as ISessionCustomer['id'] | undefined,

  lastOpenedSessionCustomerSubtab: undefined as TSubTabKey | undefined,
});

const normalizeSessionCustomer = (sessionCustomer: ISessionCustomer) => {
  // if a lastSubTab is not provided use default
  if (!sessionCustomer.lastSubTabKey) {
    // eslint-disable-next-line no-param-reassign
    sessionCustomer.lastSubTabKey = defaultSubTabKey;
  }

  return sessionCustomer;
};

export const getters = {
  sessionCustomers(): ISessionCustomer[] {
    return state.sessionCustomers;
  },

  lastOpenedSessionCustomerId(): ISessionCustomer['id'] | undefined {
    return state.lastOpenedSessionCustomerId;
  },
};

export const actions = {
  fetchSessionCustomersFromSessionStorage(): ISessionCustomer[] {
    let sessionCustomers: ISessionCustomer[] = getItem<ISessionCustomer[]>('sessionCustomers').value || [];

    // remove any duplicate sessionCustomers by id
    sessionCustomers = [
      ...new Map(sessionCustomers.map((v) => [v.id, v])).values(),
    ];

    // remove any invalid entries
    sessionCustomers = sessionCustomers.filter((sc) => sc && sc.id);

    // provided any defaults for each SessionCustomer
    sessionCustomers.forEach((sessionCustomer) => {
      normalizeSessionCustomer(sessionCustomer);
    });

    state.sessionCustomers = [...sessionCustomers];

    return state.sessionCustomers;
  },

  fetchLastOpenedSessionCustomerIdFromSessionStorage():
    | ISessionCustomer['id']
    | undefined {
    const lastOpenedSessionCustomerId = getItem<ISessionCustomer['id'] | undefined>('lastOpenedSessionCustomerId')
      .value || undefined;

    state.lastOpenedSessionCustomerId = lastOpenedSessionCustomerId;

    return state.lastOpenedSessionCustomerId;
  },
};

export const mutations = {
  saveSessionCustomers(sessionCustomers: ISessionCustomer[]): void {
    state.sessionCustomers = [...sessionCustomers];

    setItem<ISessionCustomer[]>('sessionCustomers', state.sessionCustomers);
  },

  addSessionCustomer(sessionCustomer: ISessionCustomer): ISessionCustomer {
    if (!sessionCustomer) {
      throw new Error('A sessionCustomer must be supplied');
    }

    if (
      sessionCustomer.id
      && Number.isNaN(parseInt(String(sessionCustomer.id), 10))
    ) {
      throw new Error('Unable to parse sessionCustomer.id to a number');
    }

    state.sessionCustomers = [
      normalizeSessionCustomer(sessionCustomer),
      ...state.sessionCustomers,
    ];

    setItem<ISessionCustomer[]>('sessionCustomers', state.sessionCustomers);

    return sessionCustomer;
  },

  removeSessionCustomer(sessionCustomer: ISessionCustomer): void {
    state.sessionCustomers = [
      ...state.sessionCustomers.filter((sc) => sc !== sessionCustomer),
    ];

    setItem<ISessionCustomer[]>('sessionCustomers', state.sessionCustomers);
  },

  removeAllSessionCustomers(): void {
    state.sessionCustomers = [];

    setItem<ISessionCustomer[]>('sessionCustomers', state.sessionCustomers);
  },

  setLastOpenedSessionCustomerId(
    sessionCustomerId: ISessionCustomer['id']
  ): void {
    state.lastOpenedSessionCustomerId = sessionCustomerId;

    setItem<typeof sessionCustomerId>(
      'lastOpenedSessionCustomerId',
      sessionCustomerId
    );
  },

  removeLastOpenedSessionCustomer(): void {
    this.setLastOpenedSessionCustomerId(undefined);
  },
};
