<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      app
      v-if="isLoggedIn"
    >
      <Sidebar />
    </v-navigation-drawer>

    <v-app-bar
      app
      v-if="isLoggedIn"
    >
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <div
        class="d-flex flex-row justify-center justify-center"
        style="width: 100%; position: absolute; z-index: -999;"
      >
        <v-img
          contain
          max-height="50"
          src="@/assets/images/CLYNK_Logo_CT_Branded_Purple.svg"
          alt="Logo"
        ></v-img>
      </div>

      <v-spacer></v-spacer>

      <v-menu
        bottom
        left
        v-if="isLoggedIn"
      >
        <template #activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <AccountMenu />
      </v-menu>
    </v-app-bar>

    <v-main>
      <slot></slot>
    </v-main>
  </v-app>
</template>

<script lang='ts'>
import Vue from 'vue';

import Sidebar from '@/Layout/Components/Sidebar.vue';
import CurrentUserSettingsService from '@/services/currentUserSettingsService';
import AuthService, { User } from '@/services/authService';
import AccountMenu from '@/Layout/Components/Header/AccountMenu.vue';

export default Vue.extend({
  name: 'App',

  components: {
    AccountMenu,
    Sidebar
  },

  data() {
    return {
      currentUserSettingsService: CurrentUserSettingsService,
      drawer: true
    };
  },

  computed: {
    user(): User {
      return AuthService.getAuthenticatedUser();
    },

    isLoggedIn(): boolean {
      return AuthService.isAuthenticated();
    }
  },

  watch: {
    isLoggedIn: {
      handler(newValue) {
        if (!newValue) {
          this.$vuetify.theme.dark = false;
        }
      },

      immediate: true
    },

    'currentUserSettingsService.settings': {
      handler() {
        if (this.currentUserSettingsService.settings?.Settings?.general.theme === 'Dark') {
          this.$vuetify.theme.dark = true;
        } else {
          this.$vuetify.theme.dark = false;
        }
      },

      immediate: true,
      deep: true
    }
  }
});
</script>

<style lang="scss">
</style>
