<template>
  <div id="idleTimer">
    <v-dialog v-model="showWarning">
      <v-card>
        <v-card-text>
          <div class="text-h2 pa-12">
            {{ logoutWarnMessage }}
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-idle
      @idle="remind"
      :duration="25 * 60"
    />
    <!-- Change the above duration if testing -->
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import prettyms from 'pretty-ms';
import Timer from '../../services/common/Timer';

export default Vue.extend({
  props: {
    onExpire: {
      type: Function,
      required: true,
    },
    onSecond: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {
      showWarning: false,
      warnTimer: null as (Timer | null),
      secondInterval: null as (number | null),
      millisecondsLeft: null as (number | null),
    };
  },
  methods: {
    onTimerEnd() {
      this.showWarning = false;
      if (this.warnTimer) {
        this.warnTimer.pause();
      }
      this.warnTimer = null;
      this.onExpire();
    },
    remind() {
      if (this.$route.path !== '/my-portal/register') {
        this.warnTimer = new Timer(5 * 60 * 1000, this.onExpire.bind(this)); // Change number here if you need to test
        this.warnTimer.start();
        this.showWarning = true;
        document.addEventListener('mousemove', this.onActivity);
        document.addEventListener('keydown', this.onActivity);
      }
    },
    onActivity() {
      this.showWarning = false;
      if (this.warnTimer) this.warnTimer.pause();
      this.warnTimer = new Timer(5 * 60 * 1000, this.$props.onActivity); // Change number here if you need to test
    },
  },
  computed: {
    logoutWarnMessage(): string {
      if (this.warnTimer && this.warnTimer.getTimeLeft() > 0) {
        return `You haven't done anything in a while. You will be logged out in
          ${prettyms(this.warnTimer.getTimeLeft(), { colonNotation: true, secondsDecimalDigits: 0 })}`;
      }
      return '';
    },
  },

  created() {
    this.secondInterval = setInterval(() => {
      if (this.warnTimer) {
        this.onSecond(this.warnTimer);
      }
    }, 1 * 1000);
  },
});
</script>

<style lang="scss">
.v-idle {
  position: absolute;
  top: -99999px;
  left: -99999px;
  visibility: hidden;
}
</style>
