export default class Timer {
  id?: number;

  started: Date;

  remaining: number;

  running: boolean;

  callback: () => void;

  constructor(delay = 10, callbackInp: () => void) {
    this.id = 0;
    this.started = new Date();
    this.remaining = delay;
    this.running = false;
    this.callback = callbackInp;
  }

  start():void {
    this.running = true;
    this.started = new Date();
    this.id = setTimeout(this.callback, this.remaining);
  }

  pause():void {
    this.running = false;
    clearTimeout(this.id);
    this.remaining -= new Date().getTime() - this.started.getTime();
  }

  getTimeLeft():number {
    if (this.running) {
      this.pause();
      this.start();
    }
    return this.remaining;
  }
}
